import FJLocalStore from './FJLocalStore';
import aiIcon from '../assets/icon/ai_logo.svg';
import onlineIcon from '../assets/icon/online_logo.svg';
import timeIcon from '../assets/icon/time_logo.svg';
import slideshowFormatChangeBanner from '../assets/img/slideshowMaker/slideshowFormatChangeBanner.webp';
import FJConfig from '../config/FJConfig';

export default (function () {
    let _data = null;

    return {
        setData: function (data) {
            this._data = data;
        },

        getLocalizationDataByKey: function (key, secondKey) {
            if (this._data[key][FJLocalStore.getCurrentTag()]) {
                return this._data[key][FJLocalStore.getCurrentTag()][secondKey];
            }
            return this._data[key]['en'][secondKey];
        },

        getLocalizationObtainingData: function (key) {
            if (this._data.language[FJLocalStore.getCurrentTag()]) {
                return this._data.language[FJLocalStore.getCurrentTag()][key.toLocaleLowerCase()];
            }
            return this._data.language['en'][key.toLocaleLowerCase()];
        },

        getKeyVal: function (key, val) {
            if (this._data[key]) {
                return this._data[key][val];
            }
            return '';
        },

        getAdvantagesBrickData: function () {
            return {
                title: this.getLocalizationObtainingData('benefittitle'),
                advantageList: [
                    {
                        name: this.getLocalizationObtainingData('benefit1'),
                        desc: this.getLocalizationObtainingData('benefit1desc'),
                        icon: aiIcon,
                    },
                    {
                        name: this.getLocalizationObtainingData('benefit2'),
                        desc: this.getLocalizationObtainingData('benefit2desc'),
                        icon: onlineIcon,
                    },
                    {
                        name: this.getLocalizationObtainingData('benefit3'),
                        desc: this.getLocalizationObtainingData('benefit3desc'),
                        icon: timeIcon,
                    },
                ],
            };
        },

        getScreenRecorderCardList: function (name) {
            return this.handleLists('feature', {
                title: 'feature{xxx}',
                desc: 'feature{xxx}desc',
                imgUrl: `https://resource.flexclip.com/pages/next/${name}/feature{xxx}.webp?v=${FJConfig.version}`,
            });
        },

        getStepsData: function (name) {
            return {
                title: this.getLocalizationObtainingData('steptitle'),
                stepsList: [
                    {
                        title: this.getLocalizationObtainingData('step1'),
                        desc: this.getLocalizationObtainingData('step1desc'),
                        stepSrc: `https://resource.flexclip.com/pages/next/${name}/step1.webp?v=${FJConfig.version}`,
                    },
                    {
                        title: this.getLocalizationObtainingData('step2'),
                        desc: this.getLocalizationObtainingData('step2desc'),
                        stepSrc: `https://resource.flexclip.com/pages/next/${name}/step2.webp?v=${FJConfig.version}`,
                    },
                    {
                        title: this.getLocalizationObtainingData('step3'),
                        desc: this.getLocalizationObtainingData('step3desc'),
                        stepSrc: `https://resource.flexclip.com/pages/next/${name}/step3.webp?v=${FJConfig.version}`,
                    },
                ],
            };
        },

        getQuestionList: function () {
            return this.handleLists('faq', {
                title: 'faq{xxx}',
                answer: 'faq{xxx}desc',
            });
        },

        getScreenRecorderFunctionCardData: function () {
            let num = 1;
            let lists = [];
            while (true) {
                if (!this.getLocalizationObtainingData(`tools${num}`)) {
                    break;
                }
                lists.push({
                    title: this.getLocalizationObtainingData(`tools${num}`),
                    icon: this.getKeyVal('relatedtoolsicon', `tools${num}icon`),
                    targetUrl: this.getKeyVal('relatedtoolsurl', `tools${num}url`),
                    hasLocalization: true,
                });
                num++;
            }
            return {
                title: this.getLocalizationObtainingData('relatedtoolstitle'),
                imgUrl: slideshowFormatChangeBanner,
                list: lists,
            };
        },

        handleLists: function (key, object) {
            let num = 1;
            let lists = [];
            while (true) {
                if (!this.getLocalizationObtainingData(`${key}${num}`)) {
                    break;
                }
                const obj = {};
                Object.keys(object).forEach(key => {
                    switch (key) {
                        case 'imgUrl':
                            obj[key] = object[key].replace('{xxx}', num);
                            break;
                        case 'answer':
                            let val = this.getLocalizationObtainingData(object[key].replace('{xxx}', num));
                            if (val.indexOf('xxx') !== -1) {
                                let v = this.getKeyVal('faqUrls', object[key].replace('{xxx}', num));
                                if (v.indexOf('{xxx}') !== -1 && FJLocalStore.getCurrentTag() !== 'en') {
                                    v = v.replace('{xxx}', FJLocalStore.getCurrentTag());
                                } else {
                                    v = v.replace('/{xxx}', '');
                                }
                                val = val.replace('xxx', v);
                            }
                            obj[key] = val;
                            break;
                        default:
                            obj[key] = this.getLocalizationObtainingData(object[key].replace('{xxx}', num));
                    }
                });
                lists.push(obj);
                num++;
            }
            return lists;
        },
    };
})();
