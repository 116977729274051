import React, { Component } from 'react';

import Style from './FJCssSwiper.module.less';
import YouTubeLogo from '../../../src/assets/icon/youtube.svg';
import WindowLogo from '../../../src/assets/icon/windows.svg';
import GoogleLogo from '../../../src/assets/icon/google.svg';
import AmazonLogo from '../../../src/assets/icon/amazon.svg';
import DropboxLogo from '../../../src/assets/icon/dropbox.svg';
import MetaLogo from '../../../src/assets/icon/meta.svg';
import VisaLogo from '../../../src/assets/icon/visa.svg';
import StoryBlocksLogo from '../../../src/assets/icon/storyblocks.svg';
import FJSvg from '../../FJSvg/FJSvg';

export default class FJCssSwiper extends Component {
  constructor(props) {
    super(props);
  }

  TRUSTED_ICON = [
    YouTubeLogo,
    WindowLogo,
    GoogleLogo,
    AmazonLogo,
    DropboxLogo,
    MetaLogo,
    VisaLogo,
    StoryBlocksLogo,
    YouTubeLogo,
    WindowLogo,
    GoogleLogo,
    AmazonLogo,
    DropboxLogo,
    MetaLogo,
    VisaLogo,
    StoryBlocksLogo,
  ];

  __drawIcon() {
    return this.TRUSTED_ICON.map((item, index) => {
      return (
        <div className={Style.trustedIconBox} key={'trusted platform' + index}>
          <FJSvg src={item} />
        </div>
      );
    });
  }

  render() {
    return (
      <div className={Style.layoutBox}>
        <div className={Style.logo_bbox}>{this.__drawIcon()}</div>
      </div>
    );
  }
}
